<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <ASuccessWithoutReload :api="this.api" @close="reloadPage" @click:outside="reloadPage" />
        <v-skeleton-loader v-if="api.isLoading && isFirstLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table v-else :items="data.data" :lazy="true" :loading="api.isLoading" show-expand :options.sync="options"
            :items-per-page:sync="options.itemsPerPage" :headers="dataHeader" :server-items-length="data.total"
            :sort-desc:sync="options.sortDesc" :sort-by.sync="options.sortBy" item-key="user_id"
            :expanded.sync="expanded" :footer-props="{
                itemsPerPageOptions: [20, 40, 100],
                showCurrentPage: true,
            }" dense @update:options="onPaginationUpdate">
            <template v-slot:item.index="{ item }">
                <span>
                    {{ ((options.page - 1) * options.itemsPerPage) + (data.data.indexOf(item) + 1) }}
                </span>
            </template>

            <template v-slot:top>
                <v-toolbar flat class="text-h4 pt-4" height="auto">
                    <v-col class="mx-4">
                        <v-row no-gutters>
                            Prototype Company Report
                        </v-row>
                        <v-row class="pt-3">
                            <v-col class="mt-2">
                                <v-autocomplete item-text="coupon" v-model="options.couponSearch" item-value="coupon"
                                    :items="coupons" label="Filter Coupon" dense outlined clearable>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="">
                            <v-col class="pt-0">
                                <v-text-field v-model="search" dense label="Search" outlined @input="handleSearchInput"
                                    clearable>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                <div class="d-flex justify-end">
                                    <JsonCSV :data="downloadData" :name="name">
                                        <v-btn :loading="isLoadingDownloadData" color="primary" class="mx-2">
                                            Download Report
                                        </v-btn>
                                    </JsonCSV>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-row class="ma-2 pa-2">
                        <v-col cols="12" sm="3">
                        
                            <ComponentPrototypeCompanySubscriptionHistoryTabs
                            :companyId="item.company_id"/>
                        </v-col>
                        <v-col  cols="12" sm="3">
                            <ComponentCompanyAAE
                                :companyId="item.company_id"/>
                        </v-col>
                    </v-row>
                    <v-row  class="ma-2 pa-2">
                        <v-col
                            cols="12" sm="3">
                            <ComponentPrototypeCompanySpecialRemark
                            :companyId="item.company_id"/>
                        </v-col>
                       
                        <v-col
                            cols="12" sm="3">
                         
                            <ComponentPrototypeCompanySales
                                :companyId="item.company_id"/>
                        </v-col>
                        
                    </v-row>
                    <v-row class="ma-2 pa-2">
                        <v-col
                            cols="12" sm="3">
                            <ComponentPrototypeCompanyHRDC
                            :companyId="item.company_id"/>
                            <hr style="border: 2px solid #F2F3F7;" />
                            
                        </v-col>
                        <v-col
                            cols="12" sm="3">
                            <ComponentPrototypeCompanyRefund
                            :companyId="item.company_id"/>
                        </v-col>
                      
                    </v-row>
                    <v-row
                        class="pa-2 ma-2">
                        
                        <v-col
                            cols="12" sm="3">
                            <ComponentPrototypeCompanyFunnel
                                :companyId="item.company_id"/>
                        </v-col>
                        <v-col
                            cols="12" sm="3">
                            <ComponentPrototypeCompanyClientInvoiceRecords
                                :companyId="item.company_id"/>
                                <hr style="border: 2px solid #F2F3F7;" />
                        </v-col>
                    </v-row>
                </td>
            </template>
            <template v-slot:item.company_name="{ item }">
                <router-link :to="{ name: 'PageCompaniesDetail', params: { id: item.company_id } }">
                    {{ item.company_name }}
                </router-link>
            </template>
            <template v-slot:item.bonus_offer="{ item }">
                <span v-if="item.bonus_offer != null">
                    {{ item.bonus_offer }}
                </span>
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:item.user_created_at="{ item }">
                <span v-if="item.user_created_at != null">
                    {{ convertTimeZone(item.user_created_at) }}
                </span>
                <span>
                    -
                </span>
            </template>
            <template v-slot:item.welcome_guide_sent="{ item }">
                <span v-if="item.welcome_guide_sent != null">
                    {{ convertTimeZone(item.welcome_guide_sent) }}
                </span>
                <span>
                    -
                </span>
            </template>
            <template v-slot:item.welcome_wa_sent="{ item }">
                <span v-if="item.welcome_wa_sent != null">
                    {{ convertTimeZone(item.welcome_wa_sent) }}
                </span>
                <span>
                    -
                </span>
            </template>
            <template v-slot:item.user_status="{ item }">
                <div>
                    <v-btn @click="activateAccountStatus(item.user_id, 0)" width="100" small color="green white--text"
                        v-if="item.user_status == 1">
                        Active
                    </v-btn>
                    <v-btn @click="activateAccountStatus(item.user_id, 1)" width="100" small color="red white--text" v-else>
                        Deactive
                    </v-btn>

                </div>
            </template>
            <template   
                v-slot:item.action="{item}">
                <v-row>
                    <v-col>
                        <v-menu
                            offset-y>
                            <template
                                v-slot:activator="{ on,attrs}">
                                <v-btn
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    color="primary">
                                    Actions
                                    <v-icon>
                                        <!-- {{actionDrop?'mdi-chevron-up':'mdi-chevron-down'}} -->
                                        mdi-chevron-up
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    style="font-size:16px"
                                    v-for="(data,index) in actions"
                                    :key="index"
                                    @click="executeActions(data.action,item)">
                                        {{ data.name }}
                                </v-list-item>
                            </v-list>


                        </v-menu>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.email_verified_at="{ item }">
                <div>
                    <v-btn @click="activateEmailVerification(item.user_id, 0)" width="100" small color="green white--text"
                        v-if="item.email_verified_at != null">
                        Active
                    </v-btn>
                    <v-btn @click="activateEmailVerification(item.user_id, 1)" width="100" small color="red white--text"
                        v-else>
                        Deactive
                    </v-btn>

                </div>
            </template>

        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';
import ComponentPrototypeCompanySpecialRemark from '../../../components/developer/prototype/ComponentPrototypeCompanySpecialRemark.vue';
import ComponentPrototypeCompanyHRDC from '../../../components/developer/prototype/ComponentPrototypeCompanyHRDC.vue';
import ComponentCompanyAAE from '../../company/ComponentCompanyAAE.vue';
import ComponentPrototypeCompanyFunnel from './ComponentPrototypeCompanyFunnel.vue';
import ComponentPrototypeCompanyClientInvoiceRecords from './ComponentPrototypeCompanyClientInvoiceRecords.vue';
import ComponentPrototypeCompanySales from './ComponentPrototypeCompanySales.vue';
import ComponentPrototypeCompanySubscriptionHistoryTabs from './ComponentPrototypeCompanySubscriptionHistoryTabs.vue';
import ComponentPrototypeCompanyRefund from './ComponentPrototypeCompanyRefund.vue';
// import ComponentSpecialRemark from '../../company/ComponentSpecialRemark.vue';
export default {
    components:{
    // ComponentInvoiceCompany,
    ASuccessWithoutReload,
    ComponentPrototypeCompanySpecialRemark,
    ComponentPrototypeCompanyHRDC,
    ComponentCompanyAAE,
    ComponentPrototypeCompanyFunnel,
    ComponentPrototypeCompanyClientInvoiceRecords,
    ComponentPrototypeCompanySales,
    ComponentPrototypeCompanySubscriptionHistoryTabs,
    ComponentPrototypeCompanyRefund,
},
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        isFirstLoading: true,
        name: "companies report.csv",
        data: null,
        coupons: null,
        isProcessingSearch: false,
        downloadData: null,
        search: "",
        options: {
            itemsPerPage: 20,
            page: 1,
            sortBy: [],
            search: '',
            couponSearch: '',
        },
        actions:[
            {
                name:'Update company detail',
                action:'PageCompaniesUpdate',
            },
            {
                name:'Update user detail',
                action:'PageUserUpdate',
            },
            {
                name:'Batch company form',
                action:'PageUpdateFormBatch'
            },
            {
                name:'Batch user form',
                action:'PageUpdateUserFormBatch'
            }

        ],
        actionDrop:false,
        dataHeader: [
            {
                text: ' ',
                value: 'data-table-expand'
            },
            {
                text: 'Company Id',
                value: 'company_id',
            },
            {
                text: 'Company name',
                value: 'company_name'
            },
            {
                text: 'Company type',
                value: 'company_acc_category'
            },
            {
                text: 'Coupon',
                value: 'coupon'
            },
            {
                text: 'User Id',
                value: 'user_id'
            },
            {
                text: 'First name',
                value: 'fname',
            },
            {
                text: 'Last name',
                value: 'lname'
            },
            {
                text: 'Email',
                value: 'email',
            },
            {
                text: 'Mobile',
                value: 'mobile'
            },
            {
                text: 'Status',
                value: 'user_status',
            },
            {
                text: 'Email Status',
                value: 'email_verified_at',
            },
            {
                text: 'Account',
                value: 'acc_category'
            },
            {
                text: 'Bonus Offer',
                value: 'bonus_offer'
            },
            {
                text: 'Role',
                value: 'role',
            },
            {
                text: 'AAE Role',
                value: 'aae_role',
                sortable: false,
            },
            {
                text: 'Minutes',
                value: 'minutes',
                sortable: false,
            },
            {
                text: 'Created Date',
                value: 'user_created_at'
            },
            {
                text: 'Welcome Guide Sent',
                value: 'welcome_guide_sent'
            },
            {
                text: 'Welcome WA Sent',
                value: 'welcome_wa_sent'
            },
            {
                text:"Action",
                value:'action'
            }

        ],
        expanded: [],
        isPending: false,
        isLoadingDownloadData: true,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "updateUserStatus" || resp.class === "updateUserEmailVerification") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Client Detail"
            }
            if (resp.class === "getCompanyReport") {
                this.downloadData = resp.data.map(obj => ({
                    "Company": obj.company_name,
                    "Company type": obj.company_acc_category,
                    "Coupon": obj.coupon,
                    "User Id": obj.user_id,
                    "First name": obj.fname,
                    "Last name": obj.lname,
                    "Email": obj.email,
                    "Contact number": obj.mobile,
                    "User type": obj.acc_category,
                    "Bonus Offer": obj.bonus_offer,
                    "Status": (obj.user_status == 1) ? "Verified" : "Not Verified",
                    "Email Status": (obj.email_verified_at != null) ? "Verified" : "Not Verified",
                    "Role": obj.role,
                    "AAE role": obj.aae_role,
                    "Minutes": obj.minutes,
                    "Subscribe Date": this.convertTimeZone(obj.user_created_at),
                }));
                this.isLoadingDownloadData = false;
            }
            if (resp.class === "getCompanyReportServerPaginate") {
                this.data = resp.data;
                if (this.isFirstLoading) {
                    this.downloadData = resp.data.data.map(obj => ({
                        "Company": obj.company_name,
                        "Company type": obj.company_acc_category,
                        "Coupon": obj.coupon,
                        "User Id": obj.user_id,
                        "First name": obj.fname,
                        "Last name": obj.lname,
                        "Email": obj.email,
                        "Contact number": obj.mobile,
                        "User type": obj.acc_category,
                        "Bonus Offer": obj.bonus_offer,
                        "Status": (obj.user_status == 1) ? "Verified" : "Not Verified",
                        "Email Status": (obj.email_verified_at != null) ? "Verified" : "Not Verified",
                        "Role": obj.role,
                        "AAE role": obj.aae_role,
                        "Minutes": obj.minutes,
                        "Subscribe Date": this.convertTimeZone(obj.user_created_at),
                        "Welcome Guide Sent": this.convertTimeZone(obj.welcome_guide_sent),
                        "Welcome WA Sent": this.convertTimeZone(obj.welcome_wa_sent),
                    }));
                }
                this.isFirstLoading = false;

            }
            if (resp.class === "getAllAvailableCoupon") {
                this.coupons = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchCompaniesPaginateApi = this.fetchCompaniesPaginate();
            let fetchAvailableCouponApi = this.fetchAvailableCoupon();
            let fetchCompaniesReportApi = this.fetchCompaniesReport();
            this.$api.fetch(fetchCompaniesPaginateApi);
            if (this.isFirstLoading) {
                this.$api.fetch(fetchAvailableCouponApi);
                this.$api.fetch(fetchCompaniesReportApi);
            }
        },
        fetchCompaniesPaginate() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/report/company/paginate?page=' + this.options.page + "&sortBy=" + this.options.sortBy + "&sortDesc=" + this.options.sortDesc + "&search=" + this.options.search + "&rowsPerPage=" + this.options.itemsPerPage + "&couponSearch=" + this.options.couponSearch;
            return tempApi;
        },
        fetchCompaniesReport() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/report/company';
            return tempApi;
        },
        fetchAvailableCoupon() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/coupons/available";
            return tempApi;
        },

        validateInput() {
            this.isPending = true;
        },
        cancelSubmit() {
            this.isPending = false;
        },
        submit() {
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        handleSearchInput() {
            setTimeout(() => {
                this.options.search = this.search;
            }, 1000);
        },
        onPaginationUpdate(pagination) {

            if (this.isFirstLoading) {
                this.isFirstLoading = false;
            }
            else {
                this.options = this.$_.clone(pagination);
                this.fetch();
            }
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL')
        },
        convertGetYear(time) {
            return this.$moment(time).year()
        },  
        convertTimeToDay(time){
            return this.$moment(time).format('LL')
        },
        activateAccountStatus(userId, status) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/users/status/id/" + userId;
            tempApi.params = {
                'status': status,
            };
            this.$api.fetch(tempApi);
        },
        activateEmailVerification(userId, status) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/users/email/id/" + userId;
            tempApi.params = {
                'status': status,
            }
            this.$api.fetch(tempApi);

        },
        clearInput() {
            this.search = "";
        },
        executeActions(action,company){
            if(['PageUserUpdate','PageCompaniesUpdate','PageUpdateFormBatch'].includes(action)) {
                this.$router.push({
                    name:action,
                    params:{
                        id:company.company_id,
                    },
                })
            }
            if(['PageUpdateUserFormBatch'].includes(action)) {
                this.$router.push({
                    name:action,
                });
            }
            
        },
        reloadPage() {
            this.api.isSuccesful = false;

            this.fetch();
        }
    }
}
</script>