<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.api.isLoading">
        </v-skeleton-loader>
        <v-dialog v-model="isAdding" height="700" width="700" @click:outside="() => {
            this.isAdding = false;
        }">
            <ComponentAddCompanyHRDC @finish="fetch()" v-if="isAdding" @close="() => {
                this.isAdding = false;
            }" :companyId="this.companyId" />
        </v-dialog>
        <v-dialog v-model="isUpdating" height="700" width="700" @click:outside="() => {
            this.isUpdating = false;
            this.isUpdatingId = null;
        }">
            <ComponentUpdateCompanyHRDC @finish="fetch()" v-if="isUpdating" @close="() => {
                this.isUpdating = false;
                this.isUpdatingId = null;
            }" :id="this.isUpdatingId" />
        </v-dialog>
        <div v-if="!this.api.isLoading">
            <div class="d-flex justify-space-between my-4">
                <div class="text-h6">
                    HRDC
                </div>
                <v-btn class="text-capitalize white--text rounded" color="blue lighten-1" @click="openNewModal">
                    <span class="font-weight-bold">
                        Add
                    </span>
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </div>
            <div v-if="hrdc != null">
                <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                    <v-carousel-item v-for="(item, index) in hrdc" :key="index">
                        <v-card class="px-5 pt-5 mb-4 rounded-lg" flat color="blue-grey lighten-5">
                            <div class="d-flex justify-space-between mb-4">
                                <div class="font-weight-bold mt-2">
                                    Year {{ item.year }}
                                </div>
                                <v-btn class="custom-button text-capitalize" outlined @click="openUpdateModal(item.id)">
                                    <span class="font-weight-bold">
                                        Edit
                                    </span>
                                </v-btn>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="">
                                    Invoice :
                                </p>
                                <p class="">
                                    {{ (item.hrdc_invoice != null) ? item.hrdc_invoice : '-' }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="">
                                    Year :
                                </p>
                                <p class="">
                                    {{ (item.year != null) ? item.year : '-' }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="">
                                    Year(s) of Subscription :
                                </p>
                                <p class="">
                                    {{ (item.year_of_subscription != null) ? item.year_of_subscription : '-' }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="">
                                    Invoice Sent Date :
                                </p>
                                <p v-if='item.hrdc_invoice_sent_date != null'>
                                    {{ convertTimeToDay(item.hrdc_invoice_sent_date) }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                        </v-card>
                        <div class="d-flex justify-space-between mb-4">
                            <div class="font-weight-bold mt-2">
                                {{ index + 1 }} / {{ hrdc.length }}
                            </div>
                            <v-btn class="custom-button text-capitalize" outlined
                                :to="{name:'PageHRDCList',params:{'id':companyId}}">
                                <span class="font-weight-bold">
                                    Show All
                                </span>
                            </v-btn>
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <div v-else>
                <span>No HRDC found</span>
            </div>
        </div>

    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import ComponentAddCompanyHRDC from '../../form/ComponentAddCompanyHRDC.vue';
import ComponentUpdateCompanyHRDC from '../../form/ComponentUpdateCompanyHRDC.vue';

export default {
    components: {
        ComponentAddCompanyHRDC,
        ComponentUpdateCompanyHRDC,
    },
    computed: mapState({
        //
    }),
    props: [
        'companyId'
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        actionDrop: false,
        role: null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        company: null,
        hrdc: null,
        isAdding: false,
        isUpdating: false,
        isUpdatingId: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompanyHRDCByCompany") {
                this.hrdc = (resp.data.length != 0) ? resp.data : null;
                this.api.isLoading = false;
            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchCompanyHRDCApi = this.fetchCompanyHRDC(this.companyId);
            this.isAdding = false;
            this.isUpdating = false;
            this.isUpdatingId = null;
            this.$axios.all([
                this.$api.fetch(fetchCompanyHRDCApi),
            ]);
        },
        fetchCompanyHRDC() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/hrdc/company/detail/" + this.companyId;
            return tempApi;
        },
        openNewModal() {
            this.isAdding = true;
        },
        openUpdateModal(id) {
            this.isUpdatingId = id;
            this.isUpdating = true;
        },
        convertTimeToDay(time) {
            return this.$moment(time).format("LL");
        },
    }
}
</script>

<style scoped>
.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}
</style>