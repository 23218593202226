var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AError',{attrs:{"api":this.api}}),(this.api.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"card"}}):_vm._e(),_c('v-dialog',{attrs:{"height":"700","width":"700"},on:{"click:outside":function () {
        this$1.isAdding = false;
    }},model:{value:(_vm.isAdding),callback:function ($$v) {_vm.isAdding=$$v},expression:"isAdding"}},[(_vm.isAdding)?_c('ComponentAddCompanySales',{attrs:{"companyId":this.companyId},on:{"finish":function($event){return _vm.fetch()},"close":function () {
            this$1.isAdding = false;
        }}}):_vm._e()],1),_c('v-dialog',{attrs:{"height":"700","width":"700"},on:{"click:outside":function () {
        this$1.isUpdating = false;
        this$1.isUpdatingId = null;
    }},model:{value:(_vm.isUpdating),callback:function ($$v) {_vm.isUpdating=$$v},expression:"isUpdating"}},[(_vm.isUpdating)?_c('ComponentUpdateCompanySales',{attrs:{"id":this.isUpdatingId},on:{"finish":function($event){return _vm.fetch()},"close":function () {
            this$1.isUpdating = false;
            this$1.isUpdatingId = null;
        }}}):_vm._e()],1),(!this.api.isLoading)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between my-4"},[_c('span',{staticClass:"text-h6"},[_vm._v(" Sales ")]),_c('v-btn',{staticClass:"text-capitalize white--text rounded",attrs:{"color":"blue lighten-1"},on:{"click":_vm.openNewModal}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Add ")]),_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),(_vm.sales != null)?_c('div',[_c('v-carousel',{attrs:{"height":"auto","width":"auto","continuous":false,"hide-delimiters":""}},_vm._l((_vm.sales),function(item,index){return _c('v-carousel-item',{key:index},[_c('v-card',{staticClass:"px-5 pt-5 mb-4 rounded-lg",attrs:{"flat":"","color":"blue-grey lighten-5"}},[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('div',{staticClass:"font-weight-bold mt-2"},[_vm._v(" Year "+_vm._s(_vm.convertTimeToYear(item.created_at))+" ")]),_c('v-btn',{staticClass:"custom-button text-capitalize",attrs:{"outlined":""},on:{"click":function($event){return _vm.openUpdateModal(item.id)}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Edit ")])])],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"text-no-wrap"},[_vm._v(" Salesperson : ")]),_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s((item.email == null || item.email ==='undefined') ? '-': item.email)+" ")])])]),_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('div',{staticClass:"font-weight-bold mt-2"},[_vm._v(" "+_vm._s(index + 1)+" / "+_vm._s(_vm.sales.length)+" ")]),_c('v-btn',{staticClass:"custom-button text-capitalize",attrs:{"outlined":"","to":{name:'PageSalesList',params:{'id':_vm.companyId}}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Show All ")])])],1)],1)}),1)],1):_c('div',[_c('span',[_vm._v("No sales found")])])]):_vm._e(),_c('hr',{staticStyle:{"border":"2px solid #F2F3F7"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }