<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.api.isLoading">
        </v-skeleton-loader>
        <div
            v-if="!this.api.isLoading">
                <v-dialog v-model="isAdding" height="700" width="700" @click:outside="() => {
                    this.isAdding = false;
                }">
                    <ComponentAddSubscriptionHistory @finish="fetch()" v-if="isAdding" @close="() => {
                        this.isAdding = false;
                    }" :companyId="this.companyId" />
                </v-dialog>
                <v-dialog v-model="isUpdating" height="700" width="700" @click:outside="() => {
                    this.isUpdating = false;
                    this.isUpdatingId = null;
                }">
                    <ComponentUpdateSubscriptionHistory @finish="fetch()" v-if="isUpdating" @close="() => {
                        this.isUpdating = false;
                        this.isUpdatingId = null;
                    }" :id="this.isUpdatingId" />
                </v-dialog>
                <div class="d-flex justify-space-between my-4">
                    <div class="text-h6 " v-if="!this.api.isLoading">
                        Subscription History
                    </div>
                    <v-btn class="text-capitalize white--text rounded" color="blue lighten-1" @click="openNewModal">
                        <span class="font-weight-bold">
                            Add
                        </span>
                        <v-icon>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </div>
                <div v-if="subscriptionHistory != null">
                    <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                        <v-carousel-item v-for="(item, index) in subscriptionHistory" :key="index">
                            <v-card class="px-5 pt-5 mb-4 rounded-lg" flat color="blue-grey lighten-5">
                                <div class="d-flex justify-space-between mb-4">
                                    <div class="font-weight-bold mt-2">
                                        Year {{ item.year }}
                                    </div>
                                    <v-btn class="custom-button text-capitalize" outlined @click="openUpdateModal(item.id)">
                                        <span class="font-weight-bold">
                                            Edit
                                        </span>
                                    </v-btn>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Status :
                                    </p>
                                    <p class="text-right">
                                        {{ (item.status != null) ? item.status : '-' }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Year :
                                    </p>
                                    <p class="text-right">
                                        {{ (item.year) ? item.year : '-' }}
                                    </p>
                                </div>
                                <div
                                    class="d-flex justify-space-between">
                                    <p
                                        class="text-no-wrap">
                                        Year of subscription
                                    </p>
                                    <p
                                        class="text-right">
                                        {{ (item.subscription_year!=null)?item.subscription_year:"-" }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Price :
                                    </p>
                                    <p class="text-right">
                                        {{ (item.price) ? 'RM ' + item.price : '-' }}
                                    </p>
                                </div>
                                <div
                                    class="d-flex justify-space-between">
                                    <p
                                        class="text-no-wrap">
                                        Report Date :
                                    </p>
                                    <p
                                        class="text-right">
                                        {{ convertTimeToZone(item.created_at) }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Payment Date :
                                    </p>
                                    <p class="text-right">
                                        {{ convertTimeToDay(item.payment_date) }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Subscription Start Date :
                                    </p>
                                    <p class="text-right">
                                        {{ convertTimeToDay(item.subscription_start_date) }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Subscription End Date :
                                    </p>
                                    <p class="text-right">
                                        {{ convertTimeToDay(item.subscription_end_date) }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Commission Release Date :
                                    </p>
                                    <p class="text-right">
                                        {{ (item.commision != null) ? convertTimeToDay(item.commision) : '-' }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Salesperson In Charge :
                                    </p>
                                    <p class="text-right">
                                        {{ (item.pic_salesperson != null) ? item.pic_salesperson : '-' }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Offer :
                                    </p>
                                    <p class="text-right">
                                        {{ (item.offer != null) ? item.offer : '-' }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Cancellation reason :
                                    </p>
                                    <p class="text-right">
                                        {{ (item.cancellation_reason!=null)?item.cancellation_reason:'-' }}
                                    </p>
                                </div>
                            </v-card>
                            <div class="d-flex justify-space-between mb-4">
                                <div class="font-weight-bold mt-2">
                                    {{ index + 1 }} / {{ subscriptionHistory.length }}
                                </div>
                                <v-btn class="custom-button text-capitalize" outlined
                                    :to="{name:'PageSubscriptionHistoryList',params:{'id':companyId}}">
                                    <span class="font-weight-bold">
                                        Show All
                                    </span>
                                </v-btn>
                            </div>
                        </v-carousel-item>
                    </v-carousel>
                </div>
                <div v-else>
                    <span>No subscription history found</span>
                </div>
            </div>

        <hr style="border: 2px solid #F2F3F7;" />
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import ComponentAddSubscriptionHistory from '../../form/ComponentAddSubscriptionHistory.vue';
import ComponentUpdateSubscriptionHistory from '../../form/ComponentUpdateSubscriptionHistory.vue';

export default {
    components: {
        ComponentAddSubscriptionHistory,
        ComponentUpdateSubscriptionHistory,
    },
    computed: mapState({
        //
    }),
    props: [
        'companyId'
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        actionDrop: false,
        role: null,
        page: 1,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        subscriptionHistory: null,
        aae: null,
        isAdding: false,
        isUpdating: false,
        isUpdatingId: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getSubscriptionHistoryByCompany") {
                this.subscriptionHistory = (resp.data.length != 0) ? resp.data : null;
                this.api.isLoading = false;
            }
          
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchCompanySubscriptionHistoryApi = this.fetchCompanySubscriptionHistory();
            this.isAdding = false;
            this.isUpdating = false;
            this.isUpdatingId = null;
            this.$axios.all([
                
                this.$api.fetch(fetchCompanySubscriptionHistoryApi),
            ]);
        },
        fetchCompanySubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/subscription_history/company/" + this.companyId;
            return tempApi;
        },
        openNewModal() {
            this.isAdding = true;
        },
        openUpdateModal(id) {
            this.isUpdatingId = id;
            this.isUpdating = true;
        },
        convertTimeToDay(time) {
            return this.$moment(time).format("LL");
        },
        convertTimeToZone(time) {
            return this.$moment(time).format("LLL");
        }
    },
}
</script>

<style scoped>
.custom-tabs {
    margin-top: 20px;
}

.custom-tabs .v-tab {
    border: solid #00688F;
    border-width: 2px 1px 2px 1px;
    margin-bottom: 10px;
    padding: 0px;
}

.custom-active-tab {
    background-color: #00688F;
    color: white !important;
}

.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}

/* .custom-pagination {
    margin-right: auto;
    margin-left: 300px;
} */
</style>