<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.api.isLoading">
        </v-skeleton-loader>
        <v-dialog v-model="isAdding" height="700" width="700" @click:outside="() => {
            this.isAdding = false;
        }">
            <ComponentAddCompanySales @finish="fetch()" v-if="isAdding" @close="() => {
                this.isAdding = false;
            }" :companyId="this.companyId" />
        </v-dialog>
        <v-dialog v-model="isUpdating" height="700" width="700" @click:outside="() => {
            this.isUpdating = false;
            this.isUpdatingId = null;
        }">
            <ComponentUpdateCompanySales @finish="fetch()" v-if="isUpdating" @close="() => {
                this.isUpdating = false;
                this.isUpdatingId = null;
            }" :id="this.isUpdatingId" />
        </v-dialog>
        <div v-if="!this.api.isLoading">
            <div class="d-flex justify-space-between my-4">
                <span class="text-h6">
                    Sales
                </span>
                <v-btn class="text-capitalize white--text rounded" color="blue lighten-1" @click="openNewModal">
                    <span class="font-weight-bold">
                        Add
                    </span>
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </div>
            <div v-if="sales != null">
                <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                    <v-carousel-item v-for="(item, index) in sales" :key="index">
                        <v-card class="px-5 pt-5 mb-4 rounded-lg" flat color="blue-grey lighten-5">
                            <div class="d-flex justify-space-between mb-4">
                                <div class="font-weight-bold mt-2">
                                    Year {{ convertTimeToYear(item.created_at) }}
                                </div>
                                <v-btn class="custom-button text-capitalize" outlined @click="openUpdateModal(item.id)">
                                    <span class="font-weight-bold">
                                        Edit
                                    </span>
                                </v-btn>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Salesperson :
                                </p>
                                <p class="text-right">
                                    <!-- <ACopyAbleText
                                        :text="item.email">

                                    </ACopyAbleText> -->
                                    {{ (item.email == null || item.email ==='undefined') ? '-': item.email }}
                                </p>
                            </div>
                            <!-- <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Team :
                                </p>
                                <p class="text-right">
                                    {{ (item.salesperson_team==null || item.salesperson_team=='undefined')?'-':item.salesperson_team }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Name :
                                </p>
                                <p class="text-right">
                                    {{ (item.salesperson_name==null || item.salesperson_name=='undefined')?'-':item.salesperson_name }}
                                </p>
                            </div> -->
                            <!-- <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Sales Funnel :
                                </p>
                                <p class="text-right">
                                    {{ (item.sale_funnel==null || item.sales_funnel=='undefined') ?'-':item.sales_funnel }}
                                </p>
                            </div> -->
                        </v-card>
                        <div class="d-flex justify-space-between mb-4">
                            <div class="font-weight-bold mt-2">
                                {{ index + 1 }} / {{ sales.length }}
                            </div>
                            <v-btn class="custom-button text-capitalize" outlined
                            :to="{name:'PageSalesList',params:{'id':companyId}}">
                                <span class="font-weight-bold">
                                    Show All
                                </span>
                            </v-btn>
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <div v-else>
                <span>No sales found</span>
            </div>
        </div>

        <hr style="border: 2px solid #F2F3F7;" />
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import ComponentAddCompanySales from '../../form/ComponentAddCompanySales.vue';
import ComponentUpdateCompanySales from '../../form/ComponentUpdateCompanySales.vue';

export default {
    components: {
        ComponentAddCompanySales,
        ComponentUpdateCompanySales,
    },
    computed: mapState({
        //
    }),
    props: [
        'companyId'
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        actionDrop: false,
        role: null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        company: null,
        sales: null,
        isAdding: false,
        isUpdating: false,
        isUpdatingId: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompanySalesByCompany") {
                this.sales = (resp.data.length != 0) ? resp.data : null;
                this.api.isLoading = false;
            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            // let fetchCompanyApi = this.fetchCompany(companyId);
            // let fetchSubscriptionApi = this.fetchCompanySubscription(companyId);
            // let fetchRenewalStatusApi = this.fetchRenewalStatus(companyId);
            // let fetchLatestPaymentStatusApi = this.fetchPaymentStatus(companyId);
            // let fetchRefundApi = this.fetchRefund();
            // let fetchAttachmentApi = this.fetchAttachment();
            // let fetchSpecialRemarkApi = this.fetchSpecialRemark();
            // let fetchCompanySubscriptionHistoryApi = this.fetchCompanySubscriptionHistory(companyId);
            // let fetchCompanyRefundApi = this.fetchCompanyRefund(companyId);
            let fetchCompanySalesApi = this.fetchCompanySales();
            this.isAdding = false;
            this.isUpdating = false;
            this.isUpdatingId = null;
            this.$axios.all([
                // this.$api.fetch(fetchCompanyApi),
                // this.$api.fetch(fetchSubscriptionApi),
                // this.$api.fetch(fetchRenewalStatusApi),
                // this.$api.fetch(fetchLatestPaymentStatusApi),
                // this.$api.fetch(fetchRefundApi),
                // this.$api.fetch(fetchAttachmentApi),
                // this.$api.fetch(fetchSpecialRemarkApi),
                // this.$api.fetch(fetchCompanySubscriptionHistoryApi),
                // this.$api.fetch(fetchCompanyRefundApi),
                this.$api.fetch(fetchCompanySalesApi),
            ]);
        },
        fetchCompanySales() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/sales/company/detail/" +this.companyId ;
            return tempApi;
        },
        openNewModal() {
            this.isAdding = true;
        },
        openUpdateModal(id) {
            this.isUpdatingId = id;
            this.isUpdating = true;
        },
        convertTimeToYear(time) {
            return this.$moment(time).year();
        },
    }
}
</script>

<style scoped>
.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}
</style>